@import "open-color/open-color";

.excalidraw {
  .layer-ui__library {
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    .layer-ui__library-header {
      display: flex;
      align-items: center;
      width: 100%;
      margin: 2px 0;

      .Spinner {
        margin-right: 1rem;
      }

      button {
        // 2px from the left to account for focus border of left-most button
        margin: 0 2px;
      }

      a {
        margin-inline-start: auto;
        // 17px for scrollbar (needed for overlay scrollbars on Big Sur?) + 1px extra
        padding-inline-end: 18px;
        white-space: nowrap;
      }
    }
  }

  .layer-ui__library-message {
    padding: 2em 4em;
    min-width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .Spinner {
      margin-bottom: 1em;
    }
    span {
      font-size: 0.8em;
    }
  }

  .publish-library-success {
    .Dialog__content {
      display: flex;
      flex-direction: column;
    }

    &-close.ToolIcon_type_button {
      background-color: $oc-blue-6;
      align-self: flex-end;
      &:hover {
        background-color: $oc-blue-8;
      }
      .ToolIcon__icon {
        width: auto;
        font-size: 1rem;
        color: $oc-white;
        padding: 0 0.5rem;
      }
    }
  }
}
