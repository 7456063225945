.excalidraw {
  --color-primary-contrast-offset: #625ee0; // to offset Chubb illusion

  &.theme--dark {
    --color-primary-contrast-offset: #726dff; // to offset Chubb illusion
  }
  .layer-ui__wrapper__footer-center {
    display: flex;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-inline-start: auto;
  }

  .encrypted-icon {
    border-radius: var(--space-factor);
    color: var(--color-primary);
    margin-top: auto;
    margin-bottom: auto;
    margin-inline-start: auto;
    margin-inline-end: 0.6em;

    svg {
      width: 1.2rem;
      height: 1.2rem;
    }
  }
}
.excalidraw-app.is-collaborating {
  [data-testid="clear-canvas-button"] {
    visibility: hidden;
    pointer-events: none;
  }
}
